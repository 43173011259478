import React from "react";

import Thanks from '../../components/Thanks-you'
import Layout from "../../components/layout";

export default function Home() {
  return (
    <Layout title="get-offer-no-background">
      <Thanks />
    </Layout>
  )
}
