import React,{useState, useEffect} from "react";

import { getStorage } from "../utils";
import { HOME_SCREEN } from "../constant";
import ButtonField from "../components/common/form/ButtonField";
import { YoutubeModal } from "../components/common/youTubeModal"

const ThanksScreen = () => {
  const [name, setName] = useState("")
  const [modalShow, setModalShow] = useState(false)
  const [videoUrl, setVideoUrl] = useState("")

  useEffect(() => {
    let formData = getStorage('formData')
    setName(formData.first_name)
  }, [])

  return (
    <div className="thanksOfferContainer">
      <div className="text-center pt-5 pb-5 thanks-display">
        <h1>{`${name},`}</h1>
        <h2>Thank you for requesting an offer!</h2>
        <h3 className="mb-4">You will receive a call back ASAP from a home buying specialist.</h3>
        <div className="video-content">
          <iframe
            title="iframe"
            className="videoRadio"
            srcDoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img{position:absolute;width:100%;top:0;bottom:0;margin:auto}</style><a ><img src=https://img.youtube.com/vi/LvqZ07fPFtY/hqdefault.jpg </a>"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <span
            role="none"
            onKeyDown={() => ""}
            onClick={() => {
              setVideoUrl("https://www.youtube.com/embed/LvqZ07fPFtY")
              setModalShow(true)
            }}
            className="playBotton cursor-pointer"
          >
            ▶
          </span>
        </div>
        <ButtonField
          className="btn btn-primary btn-md mt-5"
          name="Visit our homepage"
          to={HOME_SCREEN}
        />
        {/* <div className="btn btn-primary btn-md visitBtn" onClick={() => navigate(HOME_SCREEN)}>
          Visit our homepage
        </div> */}
      </div>
      <YoutubeModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        videoUrl={videoUrl}
        className="ourProcessModel"
      />
    </div>
  );
}

export default ThanksScreen;
